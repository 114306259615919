import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Title, Subtitle } from '@Components/Public';
import { desktopMinSize, mqmin, rem, mqmax } from '@Styles/mixins';

import PriceCardWrapper from '../../components/ShopPage/PriceCardWrapper';
import { getProductsPublic } from '../../services/noar-api';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const Plans = () => <Wrapper />;

export default Plans;

function Wrapper() {
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('classic');
  let { tab } = useParams();

  async function getProductsData() {
    try {
      const response = await getProductsPublic();
      setProducts(response);
    } catch (error) {
      console.log(error);
    }
  }

  function handleTab(option){
    setCurrentTab(option);
    history.replace(`/planos/${option}`)
  }

  useEffect(() => {
    if(!tab){
      setCurrentTab('personal');
    }
    else {
      setCurrentTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    getProductsData();

    if(!tab){
      setCurrentTab('personal');
      history.replace(`/planos/personal`)
    }
  }, [history, tab]);

  return (
    <Container>
      <FirstFold>
        <Header>
          <Title as="h1">
            <strong>
              Planos <span className="uppercase">Noar</span>
            </strong>
          </Title>
          <Subtitle>Nossos planos começam em apenas R$3.48 a hora. Confira!</Subtitle>
        </Header>
        <TabList>
          <Options onClick={() => handleTab('personal')} active={currentTab === 'personal'}>
            Monte seu pc
          </Options>
          <Options secondary={true} onClick={() => handleTab('classic')} active={currentTab === 'classic'}>
            Horas Avulsas
          </Options>
        </TabList>
        <PriceCardWrapper
          products={products}
          isPlanOpen={false}
          setIsPlanOpen={() =>{}}
          havePlan={false}
          havePersonal={false}
          powerUpTab={currentTab}
          isPublic={true}
        />
      </FirstFold>
    </Container>
  );
}

const Options = styled.h1`
  font-size: calc(20rem / 16);
  font-weight: 400;
  opacity: 0.4;
  margin-left: ${({secondary}) => secondary ? '15px' : '0'};
  padding: 0.4rem 0;
  cursor: pointer;
  transition: 0.2s ease-in;
  border: solid ${({ active }) => (active ? '#2d80f2' : 'transparent')} calc(1rem / 16);
  padding: 0.5rem;
  border-radius: 1rem;
  
  ${mqmax(desktopMinSize - 1)} {
    font-size: calc(14rem / 16);
  }
  ${({ active }) => active ? `opacity: 1;`: ''}
  
`;

const TabList = styled.div`
  // visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(20)};
`;

const Header = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;

  ${mqmin(desktopMinSize)} {
    row-gap: calc(7rem / 16);
  }
`;

const FirstFold = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${TabList} {
    margin-block: calc(14rem / 24) calc(44rem / 24);

    ${mqmin(desktopMinSize)} {
      margin-block: calc(14rem / 16) calc(44rem / 16);
    }
  }
`;

const H2 = styled(Title)`
  ${mqmin(desktopMinSize)} {
    text-align: center;
  }

  strong {
    display: block;
  }
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  row-gap: calc(163rem / 32);
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-inline: auto;
  padding-block: calc(32rem / 16) calc(112rem / 16);
  width: 100%;

  ${mqmin(desktopMinSize)} {
    row-gap: calc(163rem / 16);
    padding-block: calc(100rem / 16);
  }

  ${FirstFold},
  ${H2} {
    width: 100%;
    max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  }
`;
