import React, { useState, useRef, useEffect } from 'react';
import { PersonalPlanCard } from './styled';
import '@Toast/styles.css';
import { verifyPurchaseAvailability } from '@Services/noar-api';
import AssembleComputerWrapper from './AssembleComputerWrapper';
import PersonalPlanCardWrapper from './PersonalPlanCardWrapper';
import AboutPersonal from './AboutPersonalWrapper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading } from '../../../Loading';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import Payment from '../../Payment';
import { ORIGIN_PERSONAL } from '../../../../constants';

export const PersonalCard = ({ products, isOpen, disableButton, isPublic }) => {
  const [showPayment, handleShowPayment] = useState(false);
  const [selectedProductTotalAmount, setSelectedProductTotalAmount] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [phase, setPhase] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disablePaymentContent, setDisablePaymentContent] = useState(false);
  const history = useHistory();
  var dispatch = useDispatch();
  const { accountPersonalInfo } = useSelector(state => state.global);
  const messageError = {
    message: 'Ocorreu um erro ao gerar o pedido, por favor informe o código a seguir para o suporte: ',
    confirmBtnText: 'Fechar',
    refuseBtnText: '',
  };

  const modalRef = useRef();

  function generateStoragePrices() {
    if (!products) return;
    const personalStorage = products.filter(product => product.name === 'PERSONAL STORAGE')[0];
    const quantityProfits = personalStorage?.quantity_profits;
    const prices = {};

    quantityProfits.forEach(quantityProfit => {
      const price = personalStorage.price * quantityProfit.quantity * (1 + quantityProfit.profit_percentage / 100);
      prices[quantityProfit.quantity * 50] = price;
    });

    return prices;
  }
  const storagePrices = generateStoragePrices();

  async function handleOrderValidations(selectedProducts) {
    if (isPublic) {
      handlePublicRedirect(selectedProducts);
      return;
    }
    let availability = await verifyPurchaseAvailability()
      .then(() => true)
      .catch(() => {
        dispatch(openConfirmationLightbox(
          () => {
            history.push('/shop/personal');
          },
          <>Você excedeu o limite de tentativas recusadas, tente realizar novamente em 24 horas e verifique suas informações.</>,
          'Entendi',
          null,
          null,
          null,
          false,
        ));
      })
    if(!availability) return;

    await accountPersonalInfo
      .showPopupValidationFunction(
        selectedProductTotalAmount,
        selectedProductList,
        setPaymentId,
        handleShowPayment,
        false,
        true,
        setDisablePaymentContent,
        true,
      )
      .then(resp => {
        setLoading(false);
        if (!resp) {
          handleOrder(selectedProducts);
        }
      })
      .catch(() => setLoading(false));
  }

  async function handleOrder(selectedProducts) {

    const totalAmount = selectedProducts.reduce((total, selectedProduct) => {
      products.forEach(product => {
        if (selectedProduct.product_id === product.product_id) {
          if (product.name === 'PERSONAL STORAGE') {
            selectedProduct.price = storagePrices[selectedProduct.quantity * 50];
          } else {
            selectedProduct.price = product.price * selectedProduct.quantity;
          }
        }
      });

      return total + selectedProduct.price;
    }, 0);

    if (isPublic) {
      handlePublicRedirect(selectedProducts);
      return;
    }

    setSelectedProductTotalAmount(totalAmount);
    handleShowPayment(true);
  }

  const handleKnowMoreOnClassicOpenModal = () => {
    modalRef.current.showModal();
  };

  const handleKnowMoreOnClassicCloseModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const closePayment = event => {
    if (event?.target !== event?.currentTarget) return;
    setPaymentId(null);
    handleShowPayment(false);
    history.push('/shop/personal');
    window.location.reload();
  };

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/hours', state: { formattedProducts: formattedProducts } });
  };

  useEffect(() => {
    setLoading(disablePaymentContent);
  }, [disablePaymentContent]);

  useEffect(() => {
    if (paymentId) {
      handleShowPayment(false);
      dispatch(
        openConfirmationLightbox(
          () => {
            window.location.reload();
          },
          'Assinatura feita com sucesso!',
          'Fechar',
          '',
          null,
          null,
          false,
        ),
      );
      setDisablePaymentContent(false);
      history.push('/my/home');
    }
  }, [paymentId]);

  useEffect(() => {
    if (history.location.state?.formattedProducts && !disableButton) {
      setSelectedProductList(history.location.state.formattedProducts);
      setPhase(2)
    }
  }, [disableButton, history.location.state?.formattedProducts]);

  useEffect(() => {
    if(selectedProductList.length > 0){
      handleOrderValidations(selectedProductList);
    }
  }, [selectedProductList]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <PersonalPlanCard>
        <PersonalPlanCardWrapper handleKnowMoreOnClassicOpenModal={handleKnowMoreOnClassicOpenModal} />
        <AssembleComputerWrapper
          handleOrder={setSelectedProductList}
          selectedProductList={selectedProductList}
          products={products}
          isOpen={isOpen}
          disableButton={disableButton}
          isPublic={isPublic}
          phase={phase}
          setPhase={setPhase}
        />
        <AboutPersonal modalRef={modalRef} handleKnowMoreOnClassicCloseModal={handleKnowMoreOnClassicCloseModal} />
      </PersonalPlanCard>
      {showPayment ? (<Payment close={closePayment} selectedProductList={selectedProductList} productType={ORIGIN_PERSONAL} accountPersonalInfo={accountPersonalInfo}/>) : null}
    </>
  );
};
