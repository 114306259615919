import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IMAGES_URL, REDIRECT_TO_LOGIN, CLIENT_TYPES, messageDisconnect } from '@Constants';
import { SessionExpired } from '@Errors';
import { getClientHours } from '@Services/noar-api';
import { openConfirmationLightbox, updateClientHours } from '@Store/ducks/global/actions';
import * as sessionManager from '@Utils/session-manager';

import { ProtectedComponent } from '../ProtectedComponent';
import { ORIGIN_UNIT } from '@Constants';

import * as S from './styled';
import { ORIGIN_CLASSIC, ORIGIN_FREE, ORIGIN_PERSONAL, SESSION_COOKIE_NAME } from '../../constants';
import { getActivatedPlans } from '../../services/noar-api';
import { updateUnitUsedHours } from '../../store/ducks/global/actions';
import calculateHours, { calculateUnitHours } from '../../utils/calculate-hours';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useUserRole } from '../../context/UserRoleContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Cookies from 'js-cookie';


export function MobileHeader() {
  const { pathname } = useLocation();
  const retrievePlanName = getRetrievedPlanName(pathname);
  const { hours, showHours } = useHours(retrievePlanName, pathname);
  const { handleCloseClick, handleOpenClick, ref } = useDialog();
  const { clientType } = useUserRole();
  const { activePlans } = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));
  const [hasActiveUnitPlan, setHasActiveUnitPlan] = useState(false);
  const hasToShowPlanWrapper = pathname === '/use/occasional/classic' || pathname === '/use/occasional/unit';

  const links = [
    { path: '/my/home', text: clientType === 'B2B_ADM' ? 'Home' : 'Meu Computador', roles: ['B2B_ADM', 'B2B_COLLAB', 'B2C'] },
    ...(hasActiveUnitPlan === true ? [{ path: '/use/occasional/unit', text: 'Unit', roles: ['B2C'] }] : []),
    { path: '/admin/images', text: 'Imagens', roles: ['B2B_ADM'] },
    { path: '/use/occasional/classic', text: 'Classic', roles: [] },
    { path: '/shop/hours', text: 'Loja', roles: ['B2C'] },
    { path: '/my/sessions', text: 'Histórico', roles: ['B2B_ADM', 'B2B_COLLAB', 'B2C'] },
    { path: '/support', text: 'Suporte', roles: ['B2C'] },
    { path: '/my/download', text: 'Download', roles: ['B2B_COLLAB', 'B2C'] },
    { path: '/my/profile', text: 'Perfil', roles: ['B2B_ADM', 'B2B_COLLAB', 'B2C'] },
  ];

  const history = useHistory();
  const handleLogoOnClick = useCallback(
    function () {
      history.push('/use/occasional');
    },
    [history],
  );

  function getPlanAccordingToPage() {
    if (pathname === '/shop/hours') {
      return ORIGIN_CLASSIC;
    }
    else if (pathname === '/shop/unit') {
      return ORIGIN_PERSONAL;
    }
  }

  function getRetrievedPlanName(pathname) {
    return pathname.includes('/shop') ? getPlanAccordingToPage() : localStorage.getItem('selected_plan');
  }
  
  useEffect(() => {
    async function validateUnitPlan() {
      const validPlans = ["MONTHLY"];
      for (let i = 0; i < activePlans.length; i++) {
        if (validPlans.includes(activePlans[i].signature_type)) {
          setHasActiveUnitPlan(true);
        }
      }
    }
    validateUnitPlan();
  }, [activePlans]);

  return (
    <S.Container>
      <S.Div>
        <S.Logo alt={''} src={`${IMAGES_PATH}/menu-logo.png`} width={75} height={42} onClick={handleLogoOnClick} />
        {retrievePlanName && hasToShowPlanWrapper && (
          <div className='plan'>
            <span>Noar {retrievePlanName}</span>
          </div>
        )}
        {showHours && retrievePlanName ? (
          <ProtectedComponent role={['B2C']}>
            <S.Time to="/shop/hours">
              <ScheduleIcon /> {hours} {retrievePlanName === ORIGIN_UNIT ? "utilizadas" : "restantes"}
            </S.Time>
          </ProtectedComponent>
        ) : null}
        <MuiIconButton aria-label="Abrir menu" onClick={handleOpenClick}>
          <S.MenuIcon />
        </MuiIconButton>
        <S.Menu {...{ ref }}>
          <S.OptionsWrapper>
            <MuiIconButton aria-label="Fechar menu" onClick={handleCloseClick}>
              <S.CloseIcon />
            </MuiIconButton>
            <S.Links>
              {links.map(({ path, text, roles }) => (
                <ProtectedComponent role={roles}>
                  <S.TextButton component={NavLink} key={path} onClick={handleCloseClick} to={path}>
                    {text}
                  </S.TextButton>
                </ProtectedComponent>
              ))}
            </S.Links>
            <S.Links />
          </S.OptionsWrapper>
        </S.Menu>
      </S.Div>
    </S.Container>
  );
}

function useDialog() {
  const ref = useRef(null);

  const handleOpenClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.showModal();
    },
    [ref],
  );

  const handleCloseClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.close();
    },
    [ref],
  );

  return { handleCloseClick, handleOpenClick, ref };
}

function useHours(retrievePlanName, pathname) {

  if (pathname === '/my/home') {
    retrievePlanName = ORIGIN_CLASSIC;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const showHours = sessionManager.getClientType() !== CLIENT_TYPES.NOAR_PERSONAL && retrievePlanName !== ORIGIN_FREE;

  var hours = '00:00h';
  const { used_hours, contracted_hours } = useSelector(state => state.global);
  const { unit_used_minutes } = useSelector(state => state.global);

  if (retrievePlanName === ORIGIN_CLASSIC) {
    const { TotalHours, TotalMinutes } = calculateHours(used_hours, contracted_hours);

    hours = used_hours && contracted_hours >= 0 && TotalHours >= 0 && TotalMinutes >= 0
      ? `${TotalHours.toString().padStart(2, '0')}:${TotalMinutes.toString().padStart(2, '0')} h `
      : '00:00 h';
  }
  else {
    const { UnitTotalHours, UnitTotalMinutes } = calculateUnitHours(unit_used_minutes);
    hours = UnitTotalHours >= 0 && UnitTotalMinutes >= 0
      ? `${UnitTotalHours.toString().padStart(2, '0')}:${UnitTotalMinutes.toString().padStart(2, '0')} h `
      : '00:00 h';
  }

  useEffect(
    function () {
      getClientHours()
        .then(function (responseBody) {
          dispatch(updateClientHours(responseBody));
        })
        .catch(function (error) {
          if (error instanceof SessionExpired) {
            const turnOnMachine = false;
            const disconnect = true;
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push(REDIRECT_TO_LOGIN);
                },
                messageDisconnect.message,
                messageDisconnect.confirmBtnText,
                messageDisconnect.refuseBtnText,
                turnOnMachine,
                disconnect,
                false,
              ),
            );
          }
        });
    },
    [dispatch, history],
  );

  useEffect(
    function () {
      getActivatedPlans()
        .then(function (responseBody) {
          if (responseBody.length > 0) {
            dispatch(updateUnitUsedHours(responseBody[0]?.used_minutes));
          }
        })
        .catch(function (error) {
          if (error instanceof SessionExpired) {
            const turnOnMachine = false;
            const disconnect = true;
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push(REDIRECT_TO_LOGIN);
                },
                messageDisconnect.message,
                messageDisconnect.confirmBtnText,
                messageDisconnect.refuseBtnText,
                turnOnMachine,
                disconnect,
                false,
              ),
            );
          }
        });
    },
    [dispatch, history],
  );

  return { hours, showHours };
}

const IMAGES_PATH = `${IMAGES_URL}/website/private-pages/navigation`;

export default MobileHeader;
