import LoginIcon from '@mui/icons-material/Login';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq, mqmax } from '../../../../styles/mixins';
import { MdOutlineClose } from 'react-icons/md';

export const Container = props => {
  const modalRef = useRef();

  const handleKnowMoreOnFreeOpenModal = () => {
    const modal = modalRef.current;
    modal.showModal();
  };

  const handleKnowMoreOnFreeCloseModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const has_to_show_free_modal = false;

  return (
    <div {...props}>
      <Texts>
        <Title>
          Trabalhe, jogue, renderize e <strong>muito mais</strong> através de um PC{' '}
          <strong>
            <em>de última geração</em>
          </strong>
        </Title>
        <SubtitleWrapper>
          <Subtitle>Acesse agora e conheça nossos planos a partir de R$3.48 a hora</Subtitle>
        </SubtitleWrapper>
      </Texts>
      <Buttons>
        <OutlineButton component={Link} startIcon={<LoginIcon />} to="/register">
          Cadastre-se Já
        </OutlineButton>
      </Buttons>
    </div>
  );
};

export const AboutFreeWrapper = styled.dialog`
  max-width: 863px;
  width: 100%;
  height: 680px;
  border-radius: 45px;
  background-color: rgba(23, 23, 26, 0.9);
  backdrop-filter: blur(9px);
  border: 0;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    background: #ffffff;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      height: 629px;
      padding: 2px;
      border-radius: 30px;
    }
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    & > strong {
      display: block !important;
      margin-top: 61px !important;
      color: #fff;
      font-weight: 700 !important;
      font-size: 2.188rem !important;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 1rem !important;
        margin-top: 17px !important;
      }
    }

    & > ul {
      text-align: justify;
      margin-top: 34px;
      color: #fff;
      margin-left: 55px;
      margin-right: 55px;

      & > li {
        margin-bottom: 19px;
        font-weight: 300;
        font-size: 0.95rem;
        line-height: 25px;
        min-width: 670px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.75rem !important;
          line-height: 15px;
          min-width: 250px;
        }
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
      }
    }

    .close-button {
      background: transparent;
      position: absolute;
      top: 5%;
      right: 5%;

      svg {
        width: 24px;
        height: 24px;
        opacity: 0.24;
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
        top: -3%;
        right: 0;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 304px;
    height: 500px;
    border-radius: 30px;
  }
`;

export const Title = styled.p`
  --fontSize: 20;

  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(27 / var(--fontSize));
  text-align: center;

  ${mqmin(desktopMinSize)} {
    --fontSize: 35;

    line-height: calc(48 / var(--fontSize));
    text-align: start;
  }

  ${mq(1439, 1080)} {
    --fontSize: 25;
    line-height: calc(34 / var(--fontSize));
  }

  & em {
    color: transparent;
    background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
`;

export const Subtitle = styled.p`
  --fontSize: 13;

  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(21 / var(--fontSize));
  text-align: center;

  ${mqmin(desktopMinSize)} {
    --fontSize: 17;

    line-height: calc(19 / var(--fontSize));
    text-align: start;
  }
`;

export const Observation = styled.p`
  text-align: justify;
  line-height: 1rem;
  font-size: 0.7rem;
  color: #fff;
  opacity: 0.6;
  a {
    text-decoration: underline;
    color: #fff;
  }

  ${mqmin(1900)} {
    font-size: 0.9rem;
    line-height: 1.23rem;
  }
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(11rem / 16);

  margin-bottom: 25px;

  ${mqmin(desktopMinSize)} {
    row-gap: calc(24rem / 16);
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 222px;
  }
`;

function OutlineButton(props) {
  return <Button variant="contained" {...props} />;
}

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mqmin(desktopMinSize)} {
    flex-direction: row;
  }
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(14rem / 16);

  ${mqmin(desktopMinSize)} {
    align-items: start;
    row-gap: calc(42rem / 16);
  }
`;

export const ContentBanner = styled.div`
  min-width: 432px;
  width: 90%;
  height: 87px;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-direction: column;
  background: linear-gradient(269.91deg, #9d0ef6 0.07%, #2e7ef2 99.92%);
  row-gap: calc(6rem / 16);
  cursor: pointer;

  h1 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #fff;
    margin-left: 7%;
    span {
      text-transform: uppercase;
    }
  }
  h2 {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    margin-left: 7%;
    span {
      text-transform: uppercase;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
    text-align: center;
    min-width: 50px;
    width: 227px;
    height: 88px;
    border-radius: 20px;
    background: linear-gradient(269.91deg, #9d0ef6 0.07%, #2e7ef2 99.92%);
    margin-bottom: 15px;
    margin-top: 15px;
    h1 {
      font-size: 14px;
      margin-left: 0%;
      line-height: 15.26px;
    }
    h2 {
      font-size: 12px;
      margin-left: 0%;
    }
  }
`;

export default Content;
