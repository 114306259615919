import windowsImg from '@Assets/windows-image.svg';
import macImg from '@Assets/mac-image.svg';
import linuxImg from '@Assets/linux-image.svg';

export const DownloadPageData = [
  {
    image: `${windowsImg}`,
    name: 'Windows',
    link: 'https://images.noarcloud.com/noar-connect/noarConnectSetup-0.0.5.exe',
  },
  // {
  //   image: `${macImg}`,
  //   name: 'Mac',
  //   link: 'https://github.com/moonlight-stream/moonlight-qt/releases/download/v5.0.1/Moonlight-5.0.1.dmg',
  // },
  // {
  //   image: `${linuxImg}`,
  //   name: 'Linux',
  //   link: 'https://github.com/moonlight-stream/moonlight-qt/releases',
  // },
];
